
import { BridgeHandler } from '../mixins/bridge-handler'
import { Component, Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { AppHandler } from '../mixins/app-handler'
import { TranslationHandler } from '../mixins/translation-handler'
import { IFooter } from '@/types'
import { getUrlParam } from '@/utils/url'
import cms from '@/cms'

@Component({
  components: {},
})
export default class App extends Mixins(AppHandler, BridgeHandler, TranslationHandler) {
  @Getter themePrimaryColor!: string
  @Getter themeFooterIconColor!: string
  @Getter themeIsDark!: string
  @Getter themeText!: string
  @Getter themeRounded!: 'medium' | 'full' | 'none'
  @Getter campaignIsEnded!: boolean

  isLoading = true
  localeKey = ''

  get footerData () {
    return this.localeKey !== undefined ? {
      background: this.themeIsDark ? '#000' : '#fff',
      textColor: this.themeIsDark ? '#fff' : '#000',
      iconColor: this.themeIsDark ? '#000' : '#fff',
      iconBackground: this.themeFooterIconColor,
      isSquareSocial: this.themeRounded === 'none', // sets all square
      links: [
        {
          text: this.$t('footerPrivacyTitle'),
          url: this.$t('footerPrivacy'),
        },
        {
          text: this.$t('footerImprintTitle'),
          url: this.$t('footerImprint'),
        },
        {
          text: this.$t('footerTermsTitle'),
          url: this.$t('footerTerms'),
        },
      ].filter((link) => link.url && link.text),
      socialLinks: [
        { id: 'spotify', url: this.$t('footerSpotify') },
        { id: 'youtube', url: this.$t('footerYoutube') },
        { id: 'instagram', url: this.$t('footerInstagram') },
        { id: 'twitter', url: this.$t('footerTwitter') },
        { id: 'facebook', url: this.$t('footerFacebook') },
        { id: 'appleMusic', url: this.$t('footerApple') },
        { id: 'tiktok', url: this.$t('footerTiktok') },
      ].filter((link) => link.url),
    } : null
  }

  get style () {
    return {
      fontFamily: this.$themeFontFamily,
      color: this.themeText,
      background: this.themeIsDark ? '#000' : '#fff',
    }
  }

  async created () {
    try {
      await this.$appInit({ editor: false })
    } catch (e) {
      alert(e)
      console.log(e)
    }

    if (window.parent) {
      this.$bridgeListenMessages()
    }

    await this.onDetectToken()

    this.isLoading = false

    cms.newsletter.initBanner()

    if (this.campaignIsEnded) {
      this.$router.push('/end')
    }
  }

  mounted () {
    this.trackingHandler()

    if (this.$route.name === 'Success') {
      this.$router.push('/')
    }

    if (!this.campaignIsEnded && this.$route.name === 'End') {
      this.$router.push('/')
    }
  }

  trackingHandler () {
    window.dataLayer.push({
      event: 'Page Meta',
      PageType: this.$route.name,
      Environment: window.location.hostname === 'www.umg-signup.com' ? 'Production' : 'Development',
      Language: this.$tLocaleCurrent,
      ArtistName: cms.campaign.trackingArtist
    })
  }

  get tokenKey (): string {
    return `${cms.campaign.app}:${cms.campaign.appId}:token`
  }

  loadToken (): string|null {
    const urlToken = getUrlParam('access_token')
    if (urlToken) {
      this.storeToken(urlToken)
      // Delete token from URL
      const url = new URL(window.location.href)
      url.searchParams.delete('access_token')
      window.history.replaceState({}, document.title, url.toString())
      return urlToken
    }
    const storageToken = localStorage.getItem(this.tokenKey)
    return storageToken || null
  }

  storeToken (token: string) {
    localStorage.setItem(this.tokenKey, token)
  }

  deleteToken (): void {
    localStorage.removeItem(this.tokenKey)
  }

  async onDetectToken () {
    const token = this.loadToken()

    if (token) {
      try {
        await this.$store.dispatch('LOAD_USER', { token })

        cms.tracking.trackCustomer('Registration', 'Submit Successful', 'customer_registration_success', {
          method: 'Spotify'
        })
      } catch (e) {
        cms.tracking.trackCustomer('Registration', 'Submit Unsuccessful ', 'customer_registration_fail', {
          method: 'Spotify',
          error_message: (e as string),
        })
        this.deleteToken()
      }
    }
  }

  onLocale (locale: string) {
    this.$setLocale(locale)
    this.localeKey = locale
  }
}
